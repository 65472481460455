import townsUrl from "../data/contour_communes_cdea.geojson";
import logoUrl from "../img/logo-date-lieu.png";

mapboxgl.accessToken = 'pk.eyJ1Ijoib3Blbi1lZGl0IiwiYSI6ImNsM2tuZDhmMjBweDczaHFpY2x2cWIwOWMifQ.W4Sc31EokQ1IdTLp6-Rkmw';

const COLORS = {
  light: '#FDECEF',
  dark: '#002b43',
  red: '#FF0207',
  green: '#23AA66',
  purple: '#B17DBD',
  lightblue: '#007fda',
  pink: '#DD4679',
  yellow: '#FEBB02',
  orange: '#F39100',
  darkblue: '#002b43'
}

const map = new mapboxgl.Map({
  container: 'map',
  style: 'mapbox://styles/mapbox/streets-v11',
  center: [2.2478499778463554, 48.587437666786194],
  zoom: 10.5
});

let markers = [], coords, lat, lng, popup, popupContent, hoveredId = null,
  topLeft = document.querySelector("#topleft_layer h3");

map.on('load', () => {
  // displayPOIs(map);
  displayHuma(map);
  displayTowns(map);
  const defaultFilter = document.querySelector("#topright_layer .filters button:first-of-type");
  toggleDates(defaultFilter, '2023-09-15', '2023-09-17');
});

function displayPOIs(map) {
  fetch("https://raw.githubusercontent.com/hotentic/static/main/coeuressonne.json")
    .then(response => response.json())
    .then(data => {
      for (const acc of data) {
        coords = acc.GPS.split(',');
        //console.log(acc['NOM_EQU'] +  ' coords :' + coords);
        lat = parseFloat(coords[0]), lng = parseFloat(coords[1]);
        popupContent = '<h2>' + acc['NOM_EQU'] + '</h2>';
        if (acc.ADRESSE) {
          popupContent = popupContent + '<h3>Adresse :</h3><p>' + acc.ADRESSE + '</p>';
        }
        if (acc.Contact) {
          popupContent = popupContent + '<h3>Contact : </h3><p class="pre">' + cleanUpContact(acc.Contact) + '</p>';
        }
        popup = new mapboxgl.Popup({offset: 25}).setHTML(popupContent);
        markers.push(new mapboxgl.Marker({color: COLORS.lightblue}).setLngLat([lng, lat]).setPopup(popup).addTo(map));
      }
      displayHuma(map);
    });
}

function displayTowns(map) {
  map.addSource('communes', {
    'type': 'geojson',
    'data': townsUrl,
    'generateId': true
  });

  map.addLayer({
    'id': 'communes',
    'type': 'line',
    'source': 'communes',
    'paint': {
      'line-width': 3,
      'line-color': COLORS.lightblue
    }
  });
  map.addLayer({
    'id': 'communes-fond',
    'type': 'fill',
    'source': 'communes',
    'layout': {},
    'paint': {
      'fill-color': COLORS.lightblue,
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        0
      ]
    }
  });

  map.on('mousemove', 'communes-fond', (e) => {
    if (e.features.length > 0) {
      map.getCanvas().style.cursor = 'pointer';
      if (hoveredId !== null) {
        map.setFeatureState(
          { source: 'communes', id: hoveredId },
          { hover: false }
        );
      }
      if (e.features[0].id !== hoveredId) {
        topLeft.innerHTML = e.features[0].properties['nom_officiel'];
      }
      hoveredId = e.features[0].id;
      map.setFeatureState(
        { source: 'communes', id: hoveredId },
        { hover: true }
      );
    }
  });


  map.on('mouseleave', 'communes-fond', () => {
    if (hoveredId !== null) {
      map.setFeatureState(
        { source: 'communes', id: hoveredId },
        { hover: false }
      );
    }
    hoveredId = null;
    topLeft.innerHTML = '';
  });
}

function displayHuma(map) {
  popup = new mapboxgl.Popup({offset: 25})
    .setHTML('<p id="huma-popup"><img src="' + logoUrl + '" alt="Fete de l\'Humanite 2023"/><br/><a href="https://fete.humanite.fr/" target="_blank">https://fete.humanite.fr/</a></p>')
    .addTo(map);
  const markerElt = document.createElement('div');
  markerElt.className = 'huma-marker';

  new mapboxgl.Marker(markerElt).setLngLat([2.323, 48.597]).setPopup(popup).addTo(map);
}

function cleanUpContact(contact) {
  let c = contact.replace('Tél:', 'Tél :').replace('Email:', 'Email :').replace('Site web:', 'Site web :');
  return c.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
}

window.toggleDates = function(btnElt, from, to) {
  const activeBtn = document.querySelector(".filters button.active");
  if (activeBtn) {
    activeBtn.classList.remove('active');
  }
  fetch(`https://api.open-edit.io/planning/37/disponibilites?startDate=${from}&endDate=${to}&people=1&details=true`)
    .then(response => response.json())
    .then(data => {
      markers.forEach((marker) => marker.remove());
      for (const acc of data) {
        if (acc.lng && acc.lat) {
          popupContent = '<h2>' + acc.label + '</h2>';
          if (acc.desc) {
            popupContent = popupContent + '<p>' + acc.desc + '</p>';
          }
          if (acc.address) {
            popupContent = popupContent + '<h3>Adresse :</h3><p>' + acc.address + '</p>';
          }
          if (acc.contact) {
            popupContent = popupContent + '<h3>Contact : </h3><p class="pre">' + cleanUpContact(acc.contact) + '</p>';
          }
          popup = new mapboxgl.Popup({offset: 25}).setHTML(popupContent);
          markers.push(new mapboxgl.Marker({color: acc.available ? COLORS.green : COLORS.red}).setLngLat([acc.lng, acc.lat]).setPopup(popup).addTo(map));
        }
      }
      btnElt.classList.add('active');
    });
}
